/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { mutationCodes } from "../../../graphql/enum";
import createQuestionnaire from "../../../graphql/mutation/createQuestionnaire";
import { getQuestionnaireUrl, homeUrl } from "../../../routes";
import { validateEmail, validatePhone } from "../../../utility";
import Page from "../../layout/Page";
import PreviewButtonBlock from "./PreviewButtonBlock";
import QuestionnaireCreateDetaly from "./QuestionnaireCreateDetaly";
import CreateLookingBlock from "./QuestionnaireCreateLookingBlock";
import TitleBlock from "./QuestionnaireCreateTitleBlock";

const getValuesFromArray = (values) => {
  if (!values[0].array) return null;
  const newArray = values[0].array.map((v, idx) => {
    const r = {};
    values.map(({ key, array }) => {
      r[key] = array[idx];

      return null;
    });
    return r;
  });
  return newArray;
};

const QuestionnaireCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [values, setValues] = useState({
    workExperience: [{}],
    education: [{}],
    professions: [],
    postIndexIds: []
  });
  const breadcrumbsSecond = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "previewAndSendPost",
      title: t(`summaryOfTheJobSeekerTab.title`),
      link: "#"
    }
  ];
  const [createQuestionnaireMutation] = useMutation(createQuestionnaire);

  const validate = (valuesValidate) => {
    const errors = {};
    values.education.forEach((value, idx) => {
      const nameEducationalInstitution =
        valuesValidate.nameEducationalInstitution ?? [];
      if (value.education && !nameEducationalInstitution[idx]) {
        errors[`education.${idx}`] = `validationErrors.required`;
      }
    });

    values.workExperience.forEach((value, idx) => {
      const placeOfWork = valuesValidate.placeOfWork ?? [];
      if (value.workedByProfessionId && !placeOfWork[idx]) {
        errors[`workExperience.${idx}`] = `validationErrors.required`;
      }
    });

    if (!valuesValidate.name || valuesValidate.name.length < 3) {
      errors.name = "validationErrors.required";
    }

    if (!validatePhone(valuesValidate.phone)) {
      errors.phone = `validationErrors.required`;
    }
    if (!values.cityInEngland) {
      errors.cityInEngland = `validationErrors.required`;
    }
    if (valuesValidate.email && !validateEmail(valuesValidate.email)) {
      errors.email = `validationErrors.required`;
    }
    if (!values.categoryProfession) {
      errors.categoryProfession = `validationErrors.required`;
    }
    if (!values.professions.length) {
      errors.professions = `validationErrors.required`;
    }
    if (!values.sex) {
      errors.sex = `validationErrors.required`;
    }
    if (valuesValidate.age < 18 || valuesValidate.age > 80) {
      errors.age = "validationErrors.required";
    }
    return errors;
  };
  const onSave = async (valuesFormik, { setErrors }) => {
    const valid = validate(valuesFormik);
    setErrors(valid);

    const titleBlockValues = {
      name: valuesFormik.name,
      email: valuesFormik.email,
      phone: valuesFormik.phone,
      isViber: valuesFormik.viber,
      isTelegram: valuesFormik.telegram,
      isWhatsapp: valuesFormik.whatsApp,
      age: valuesFormik.age,
      sex: values.sex,
      cityId: values.cityInEngland,
      postIndexIds: values.postIndexIds,
      imageId: values.imageId
    };

    const createLookingBlockValues = {
      isYouCanWorkOnWeekends: valuesFormik.youCanWorkOnWeekends,
      isWorkForAMarriedCouple: valuesFormik.workForAMarriedCouple,
      categoryProfessionId: values.categoryProfession,
      professionIds: values.professions,
      isAssistantMaster: valuesFormik.isAssistantMaster,
      resumeFileId: values.fileId
    };
    const questionnaireCreateDetalyValues = {
      isGotHheToolsToDoTheJob: valuesFormik.gotHheToolsToDoTheJob,
      isNonSmoker: valuesFormik.isNonSmoker,
      isIHaveCar: valuesFormik.iHaveCar,
      note: valuesFormik.note,
      documentsForWorkInEngland: values.documentsForWorkInEngland,
      constructionDocuments: values.constructionDocuments,
      knowledgeOfEnglish: values.knowledgeOfEnglish,
      driverLicense: values.driverLicense,
      workExperience: getValuesFromArray([
        { key: "placeOfWork", array: valuesFormik.placeOfWork },
        {
          key: "workedByProfessionId",
          array: values.workExperience.map(
            ({ workedByProfessionId }) => workedByProfessionId
          )
        }
      ]),

      education: getValuesFromArray([
        {
          key: "nameEducationalInstitution",
          array: valuesFormik.nameEducationalInstitution
        },
        {
          key: "education",
          array: values.education.map(({ education }) => education)
        },
        {
          key: "additionalEducation",
          array: values.education.map(
            ({ additionalEducation }) => additionalEducation
          )
        }
      ])
    };

    const { data } = await createQuestionnaireMutation({
      variables: {
        input: {
          ...titleBlockValues,
          ...createLookingBlockValues,
          ...questionnaireCreateDetalyValues
        }
      }
    });

    if (data?.createQuestionnaire?.code === mutationCodes.authorBlocked) {
      setErrors({
        phone: `validationErrors.authorBlocked`
      });
      return;
    }

    const url = queryString.stringifyUrl({
      url: getQuestionnaireUrl(data.createQuestionnaire.id),
      query: {}
    });
    history.push(url);
  };

  return (
    <Page breadcrumbs={breadcrumbsSecond} pageName={t("summaryOfTheJobSeeker")}>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          viber: false,
          telegram: false,
          whatsApp: false,
          age: null,
          workForAMarriedCouple: false,
          youCanWorkOnWeekends: false,
          gotHheToolsToDoTheJob: false,
          isNonSmoker: false,
          iHaveCar: false,
          note: ""
        }}
        onSubmit={onSave}
        validate={validate}
      >
        {({
          errors,
          validateForm,
          values: valuesFormik,
          setValues: setValuesFormik,
          submitForm,
          isSubmitting
        }) => (
          <Form>
            <TitleBlock
              validateForm={validateForm}
              errors={errors}
              values={values}
              setValues={setValues}
              setValuesFormik={setValuesFormik}
              valuesFormik={valuesFormik}
            />
            <CreateLookingBlock
              validateForm={validateForm}
              errors={errors}
              values={values}
              setValues={setValues}
            />
            <QuestionnaireCreateDetaly
              valuesFormik={valuesFormik}
              values={values}
              setValues={setValues}
              validateForm={validateForm}
              errors={errors}
              setValuesFormik={setValuesFormik}
            />
            <PreviewButtonBlock
              submitForm={submitForm}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Page>
  );
};

QuestionnaireCreate.propTypes = {
  editPostData: PropTypes.shape({
    description: PropTypes.string,
    imagesIds: PropTypes.array,
    isAgency: PropTypes.bool,
    title: PropTypes.string,
    linkYouTube: PropTypes.string,
    isSendToEmail: PropTypes.bool,
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      isViber: PropTypes.bool,
      isTelegram: PropTypes.bool,
      isWhatsapp: PropTypes.bool
    })
  }),
  code: PropTypes.string,
  refetch: PropTypes.func
};

QuestionnaireCreate.defaultProps = {
  editPostData: {}
};

export default QuestionnaireCreate;
