/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { createPostMutationsCodes, postTypes } from "../../../../graphql/enum";
import createPost from "../../../../graphql/mutation/createPost";
import updatePost from "../../../../graphql/mutation/updatePost";
import { addPostUrl } from "../../../../routes";
import {
  clearHTMLTags,
  getCreatePostTypeUser,
  getValueId,
  getValues,
  getValuesBoolean,
  removeSpaces,
  validateEmail,
  validatePhone
} from "../../../../utility";
import Loading from "../../../layout/Loading/Loading";
import ContactInformationBlock from "../CreatePostLayout/ContactInformationBlock";
import ContainerAddPost from "../CreatePostLayout/ContainerAddPost";
import PreviewButtonBlock from "../CreatePostLayout/PreviewButtonBlock";
import { getUrlAfterFirstStateUpdate } from "../CreatePostLayout/utils";
import FirstBlock from "./FirstBlock";

export const allDataQuery = gql`
  query allData {
    cities {
      id
      title
    }
    postIndexs {
      id
      title
    }
    postAnnouncementCategory {
      id
      title
      postAnnouncementRubric {
        id
        title
      }
    }
  }
`;

const Announcement = ({ editPostData, variablesAccessEdit, refetch, user }) => {
  const history = useHistory();
  const addressMap = getValues(
    editPostData?.addressMap,
    variablesAccessEdit.isEdit
  );
  const [values, setValues] = useState({
    description: getValues(
      editPostData?.description,
      variablesAccessEdit.isEdit
    ),
    postIndexIds: getValueId(
      editPostData?.postIndexIds,
      variablesAccessEdit.isEdit
    ),
    postAnnouncementCategory: getValueId(
      editPostData?.announcement?.postAnnouncementCategoryId,
      variablesAccessEdit.isEdit
    ),
    postAnnouncementRubric: getValueId(
      editPostData?.announcement?.postAnnouncementRubricId,
      variablesAccessEdit.isEdit
    ),
    cityInEngland: getValueId(
      editPostData?.announcement?.cityId,
      variablesAccessEdit.isEdit
    ),
    imagesIds: variablesAccessEdit.isEdit
      ? editPostData?.images.map(({ id }) => id)
      : [],
    typeUser: getCreatePostTypeUser(
      editPostData?.isAgency,
      variablesAccessEdit.isEdit
    ),
    address: addressMap
      ? { title: addressMap.title, lat: addressMap.lat, lng: addressMap.lng }
      : null
  });
  const [createPostMutation] = useMutation(createPost);
  const [updatePostMutation] = useMutation(updatePost);

  const validate = (valuesValidate) => {
    const errors = {};

    if (!valuesValidate.title) {
      errors.title = `validationErrors.required`;
    }
    if (valuesValidate.email && !validateEmail(valuesValidate.email)) {
      errors.email = `validationErrors.required`;
    }
    if (removeSpaces(valuesValidate.title).length < 10) {
      errors.title = `validationErrors.min10`;
    }

    if (valuesValidate.email && !validateEmail(valuesValidate.email)) {
      errors.email = `validationErrors.required`;
    }
    if (!validatePhone(valuesValidate.phone)) {
      errors.phone = `validationErrors.required`;
    }
    if (removeSpaces(clearHTMLTags(values?.description)).length < 20) {
      errors.description = `validationErrors.min20`;
    }
    if (clearHTMLTags(values?.description)?.length > 500) {
      errors.description = `validationErrors.max500`;
    }
    if (!values.description) {
      errors.description = `validationErrors.required`;
    }
    if (!values.postAnnouncementCategory) {
      errors.postAnnouncementCategory = `validationErrors.required`;
    }
    if (!values.postAnnouncementRubric) {
      errors.postAnnouncementRubric = `validationErrors.required`;
    }
    return errors;
  };
  const onSave = async (valuesFormik, { setErrors }) => {
    const valid = validate(valuesFormik);

    setErrors(valid);

    if (variablesAccessEdit.isEdit) {
      const { data: updatePostData } = await updatePostMutation({
        variables: {
          ...variablesAccessEdit.variables,
          input: {
            title: valuesFormik.title,
            description: values.description,
            linkYouTube: valuesFormik.linkYouTube,
            name: valuesFormik.name,
            email: valuesFormik.email,
            phone: valuesFormik.phone.toString(),
            isViber: valuesFormik.viber,
            isTelegram: valuesFormik.telegram,
            isWhatsapp: valuesFormik.whatsApp,
            isSendToEmail: valuesFormik.isSendMessageEmail,
            isAgency: values.typeUser === "agency",
            postIndexIds: values.postIndexIds,
            cityId: values.cityInEngland,
            type: postTypes.announcements,
            imagesIds: values.imagesIds,
            addressMap: values.address,
            postAnnouncementRubricId: values.postAnnouncementRubric,
            postAnnouncementCategoryId: values.postAnnouncementCategory
          }
        }
      });
      if (
        updatePostData?.updatePost?.code ===
        createPostMutationsCodes.authorBlocked
      ) {
        setErrors({
          phone: `validationErrors.authorBlocked`
        });
        return;
      }
      if (!updatePostData?.updatePost) {
        return;
      }
      if (
        updatePostData?.updatePost?.code ===
        createPostMutationsCodes.uniquenessFaild
      ) {
        setErrors({
          description: `validationErrors.uniquenessFaild`
        });
        return;
      }
      const url = getUrlAfterFirstStateUpdate({
        variablesAccessEdit,
        postSeo: editPostData.seo
      });

      await refetch();
      history.push(url);
      return;
    }
    const { data } = await createPostMutation({
      variables: {
        input: {
          title: valuesFormik.title,
          description: values.description,
          linkYouTube: valuesFormik.linkYouTube,
          name: valuesFormik.name,
          email: valuesFormik.email,
          phone: valuesFormik.phone.toString(),
          isViber: valuesFormik.viber,
          isTelegram: valuesFormik.telegram,
          isWhatsapp: valuesFormik.whatsApp,
          isSendToEmail: valuesFormik.isSendMessageEmail,
          isAgency: values.typeUser === "agency",
          postIndexIds: values.postIndexIds,
          cityId: values.cityInEngland,
          type: postTypes.announcements,
          imagesIds: values.imagesIds,
          addressMap: values.address,
          postAnnouncementCategoryId: values.postAnnouncementCategory,
          postAnnouncementRubricId: values.postAnnouncementRubric
        }
      }
    });
    if (!data?.createPost) {
      return;
    }

    if (data?.createPost?.code === createPostMutationsCodes.authorBlocked) {
      setErrors({
        phone: `validationErrors.authorBlocked`
      });
      return;
    }

    if (data?.createPost?.code === createPostMutationsCodes.uniquenessFaild) {
      setErrors({
        description: `validationErrors.uniquenessFaild`
      });
      return;
    }
    const url = queryString.stringifyUrl({
      url: addPostUrl,
      query: {
        code: data.createPost.accessCode
      }
    });
    history.push(url);
  };
  const { loading, data } = useQuery(allDataQuery);
  if (loading) return <Loading />;
  if (!data) return null;

  return (
    <>
      <Formik
        initialValues={{
          title: getValues(editPostData?.title, variablesAccessEdit.isEdit),
          linkYouTube: getValues(
            editPostData?.linkYouTube,
            variablesAccessEdit.isEdit
          ),
          name:
            getValues(editPostData?.user?.name, variablesAccessEdit.isEdit) ||
            (!variablesAccessEdit.isEdit ? user?.name : ""),
          email:
            getValues(editPostData?.user?.email, variablesAccessEdit.isEdit) ||
            (!variablesAccessEdit.isEdit ? user?.email : ""),
          phone:
            getValues(editPostData?.user?.phone, variablesAccessEdit.isEdit) ||
            (!variablesAccessEdit.isEdit ? user?.phone : ""),
          viber: getValuesBoolean(
            editPostData?.user?.isViber,
            variablesAccessEdit.isEdit
          ),
          telegram: getValuesBoolean(
            editPostData?.user?.isTelegram,
            variablesAccessEdit.isEdit
          ),
          whatsApp: getValuesBoolean(
            editPostData?.user?.isWhatsapp,
            variablesAccessEdit.isEdit
          ),
          isSendMessageEmail: getValuesBoolean(
            editPostData?.isSendToEmail,
            variablesAccessEdit.variables
          )
        }}
        onSubmit={onSave}
        validate={validate}
      >
        {({
          errors,
          validateForm,
          values: valuesFormik,
          setValues: setValuesFormik,
          submitForm,
          isSubmitting
        }) => (
          <Form>
            <ContainerAddPost>
              <FirstBlock
                {...{
                  data,
                  errors,
                  values,
                  setValues,
                  validateForm,
                  valuesFormik,
                  setValuesFormik,
                  images: editPostData?.images
                }}
              />
            </ContainerAddPost>
            <ContactInformationBlock
              errors={errors}
              values={values}
              valuesFormik={valuesFormik}
              setValues={setValues}
            />
            <PreviewButtonBlock
              variablesAccessEdit={variablesAccessEdit}
              values={values}
              submitForm={submitForm}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

Announcement.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  editPostData: PropTypes.shape({
    description: PropTypes.string,
    images: PropTypes.array,
    isAgency: PropTypes.bool,
    title: PropTypes.string,
    linkYouTube: PropTypes.string,
    seo: PropTypes.string,
    isSendToEmail: PropTypes.bool,
    addressMap: PropTypes.object,
    postIndexIds: PropTypes.array,
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      isViber: PropTypes.bool,
      isTelegram: PropTypes.bool,
      isWhatsapp: PropTypes.bool
    }),
    announcement: PropTypes.shape({
      cityId: PropTypes.string,
      cityInEngland: PropTypes.string,
      postAnnouncementCategoryId: PropTypes.string,
      postAnnouncementRubricId: PropTypes.string
    })
  }),
  variablesAccessEdit: PropTypes.shape({
    variables: PropTypes.shape({
      code: PropTypes.string,
      postId: PropTypes.string
    }),
    isEdit: PropTypes.bool
  }),
  refetch: PropTypes.func
};

Announcement.defaultProps = {
  editPostData: {}
};

export default Announcement;
