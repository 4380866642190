import { gql } from "@apollo/client";

const authorBlocked = gql`
  mutation authorBlocked($phone: String!) {
    authorBlocked(phone: $phone) {
      success
      code
    }
  }
`;

export default authorBlocked;
