/* eslint-disable max-statements */

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const SuccessSendRegistarionEmail = ({ email }) => {
  const { t } = useTranslation();

  return (
    <div className="account-confirm">
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6">
          <div className="account-confirm-logo">
            <img src="/assets/img/email/logo.png" alt="" />
          </div>
          <h1 className="account-confirm-heading">
            {t("registration.messageSentTo", { email })}
          </h1>
          <p className="text-center">
            {t("registration.sentYouAMessage")}
            {t("registration.sentYouAMessage2")}
          </p>
        </div>
      </div>
    </div>
  );
};

SuccessSendRegistarionEmail.propTypes = {
  email: PropTypes.string
};
export default SuccessSendRegistarionEmail;
