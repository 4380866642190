/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { createPostMutationsCodes, postTypes } from "../../../../graphql/enum";
import createPost from "../../../../graphql/mutation/createPost";
import updatePost from "../../../../graphql/mutation/updatePost";
import { getFromIsoDate } from "../../../../graphql/utils/date";
import { addPostUrl } from "../../../../routes";
import {
  clearHTMLTags,
  getCreatePostTypeUser,
  getValueId,
  getValues,
  getValuesBoolean,
  removeSpaces,
  validateEmail,
  validatePhone
} from "../../../../utility";
import Loading from "../../../layout/Loading/Loading";
import ContactInformationBlock from "../CreatePostLayout/ContactInformationBlock";
import ContainerAddPost from "../CreatePostLayout/ContainerAddPost";
import FieldsAddPost, {
  fieldsAddPostTypes
} from "../CreatePostLayout/FieldsAddPost";
import PreviewButtonBlock from "../CreatePostLayout/PreviewButtonBlock";
import { getUrlAfterFirstStateUpdate } from "../CreatePostLayout/utils";
import FirstBlock from "./FirstBlock";
import SecondBlock from "./SecondBlock";

export const allDataQuery = gql`
  query allData {
    carrierCountrys {
      id
      title
    }
    cities {
      id
      title
    }
  }
`;

const Carrier = ({ editPostData, variablesAccessEdit, refetch, user }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const addressMap = getValues(
    editPostData?.addressMap,
    variablesAccessEdit.isEdit
  );
  const [values, setValues] = useState({
    description: getValues(
      editPostData?.description,
      variablesAccessEdit.isEdit
    ),
    countryDeparture: getValueId(
      editPostData?.carrier?.carrierCountryDepartureId,
      variablesAccessEdit.isEdit
    ),
    countryArrival: getValueId(
      editPostData?.carrier?.carrierCountryArrivalId,
      variablesAccessEdit.isEdit
    ),
    countrysAlongRoute: variablesAccessEdit.isEdit
      ? editPostData?.carrier?.countryAlongRouteIds
      : [],
    imagesIds: variablesAccessEdit.isEdit
      ? editPostData?.images.map(({ id }) => id)
      : [],
    typeUser: getCreatePostTypeUser(
      editPostData?.isAgency,
      variablesAccessEdit.isEdit
    ),
    dateDeparture: getValues(
      editPostData?.carrier?.dateDeparture,
      variablesAccessEdit.isEdit
    ),
    address: addressMap
      ? { title: addressMap.title, lat: addressMap.lat, lng: addressMap.lng }
      : null
  });

  const { loading, data } = useQuery(allDataQuery);
  const [createPostMutation] = useMutation(createPost);
  const [updatePostMutation] = useMutation(updatePost);

  const validate = (valuesValidate) => {
    const errors = {};
    if (!valuesValidate.title) {
      errors.title = `validationErrors.required`;
    }
    if (removeSpaces(valuesValidate.title).length < 10) {
      errors.title = `validationErrors.min10`;
    }

    if (valuesValidate.email && !validateEmail(valuesValidate.email)) {
      errors.email = `validationErrors.required`;
    }
    if (!validatePhone(valuesValidate.phone)) {
      errors.phone = `validationErrors.required`;
    }
    if (removeSpaces(clearHTMLTags(values?.description)).length < 20) {
      errors.description = `validationErrors.min20`;
    }
    if (clearHTMLTags(values?.description)?.length > 500) {
      errors.description = `validationErrors.max500`;
    }
    if (!values.description) {
      errors.description = `validationErrors.required`;
    }
    if (!values.countryDeparture) {
      errors.countryDeparture = `validationErrors.required`;
    }
    if (!values.countryArrival) {
      errors.countryArrival = `validationErrors.required`;
    }
    if (!values.dateDeparture) {
      errors.dateDeparture = `validationErrors.required`;
    }

    return errors;
  };
  const onSave = async (valuesFormik, { setErrors }) => {
    const valid = validate(valuesFormik);
    setErrors(valid);

    const titleBlock = {
      title: valuesFormik.title,
      carrierCountryDepartureId: values.countryDeparture,
      carrierCountryArrivalId: values.countryArrival,
      cityDeparture: valuesFormik.cityDeparture,
      cityArrival: valuesFormik.cityArrival,
      cityAlongRoute: valuesFormik.cityAlongRoute,
      dateDeparture: getFromIsoDate(values.dateDeparture),
      countryAlongRouteIds: values.countrysAlongRoute
    };

    const descriptionBlock = {
      description: values.description,
      linkYouTube: valuesFormik.linkYouTube,
      imagesIds: values.imagesIds
    };

    const contactBlock = {
      name: valuesFormik.name,
      email: valuesFormik.email,
      phone: valuesFormik.phone.toString(),
      isViber: valuesFormik.viber,
      isTelegram: valuesFormik.telegram,
      isWhatsapp: valuesFormik.whatsApp,
      isSendToEmail: valuesFormik.isSendMessageEmail,
      addressMap: values.address,
      isAgency: values.typeUser === "agency"
    };

    if (variablesAccessEdit.isEdit) {
      const { data: updatePostData } = await updatePostMutation({
        variables: {
          ...variablesAccessEdit.variables,
          input: {
            type: postTypes.carrier,
            ...titleBlock,
            ...descriptionBlock,
            ...contactBlock
          }
        }
      });

      if (
        updatePostData?.updatePost?.code ===
        createPostMutationsCodes.authorBlocked
      ) {
        setErrors({
          phone: `validationErrors.authorBlocked`
        });
        return;
      }

      if (
        updatePostData?.updatePost?.code ===
        createPostMutationsCodes.uniquenessFaild
      ) {
        setErrors({
          description: `validationErrors.uniquenessFaild`
        });
        return;
      }
      const url = getUrlAfterFirstStateUpdate({
        variablesAccessEdit,
        postSeo: editPostData.seo
      });

      await refetch();
      history.push(url);
      return;
    }

    const { data: dataMutation } = await createPostMutation({
      variables: {
        input: {
          type: postTypes.carrier,
          ...titleBlock,
          ...descriptionBlock,
          ...contactBlock
        }
      }
    });

    if (
      dataMutation?.createPost?.code === createPostMutationsCodes.authorBlocked
    ) {
      setErrors({
        phone: `validationErrors.authorBlocked`
      });
      return;
    }

    if (
      dataMutation?.createPost?.code ===
      createPostMutationsCodes.uniquenessFaild
    ) {
      setErrors({
        description: `validationErrors.uniquenessFaild`
      });
      return;
    }
    const url = queryString.stringifyUrl({
      url: addPostUrl,
      query: {
        code: dataMutation.createPost.accessCode
      }
    });
    history.push(url);
  };

  if (loading) return <Loading />;
  if (!data) return null;
  return (
    <Formik
      initialValues={{
        title: getValues(editPostData?.title, variablesAccessEdit.isEdit),
        linkYouTube: getValues(
          editPostData?.linkYouTube,
          variablesAccessEdit.isEdit
        ),
        name:
          getValues(editPostData?.user?.name, variablesAccessEdit.isEdit) ||
          (!variablesAccessEdit.isEdit ? user?.name : ""),
        email:
          getValues(editPostData?.user?.email, variablesAccessEdit.isEdit) ||
          (!variablesAccessEdit.isEdit ? user?.email : ""),
        phone:
          getValues(editPostData?.user?.phone, variablesAccessEdit.isEdit) ||
          (!variablesAccessEdit.isEdit ? user?.phone : ""),
        viber: getValuesBoolean(
          editPostData?.user?.isViber,
          variablesAccessEdit.isEdit
        ),
        telegram: getValuesBoolean(
          editPostData?.user?.isTelegram,
          variablesAccessEdit.isEdit
        ),
        whatsApp: getValuesBoolean(
          editPostData?.user?.isWhatsapp,
          variablesAccessEdit.isEdit
        ),
        cityDeparture: getValues(
          editPostData?.carrier?.cityDeparture,
          variablesAccessEdit.isEdit
        ),
        cityArrival: getValues(
          editPostData?.carrier?.cityArrival,
          variablesAccessEdit.isEdit
        ),
        cityAlongRoute: getValues(
          editPostData?.carrier?.cityAlongRoute,
          variablesAccessEdit.isEdit
        ),
        isSendMessageEmail: getValuesBoolean(
          editPostData?.isSendToEmail,
          variablesAccessEdit.isEdit
        )
      }}
      onSubmit={onSave}
      validate={validate}
      validateOnChange
    >
      {({
        errors,
        validateForm,
        values: valuesFormik,
        submitForm,
        isSubmitting,
        setValues: setValuesFormik
      }) => (
        <Form>
          <ContainerAddPost>
            <FirstBlock
              {...{
                data,
                errors,
                values,
                setValues,
                validateForm,
                valuesFormik,
                setValuesFormik
              }}
            />
          </ContainerAddPost>
          <ContainerAddPost
            title={t("createPost.optionalFields")}
            description={t("createPost.optionalFieldsDescription")}
          >
            <SecondBlock
              {...{
                data,
                errors,
                values,
                setValues,
                validateForm,
                images: editPostData?.images,
                valuesFormik
              }}
            />
          </ContainerAddPost>
          <ContactInformationBlock
            thirdColums={
              <>
                <div className="mb-3">
                  <FieldsAddPost
                    valuesFormik={valuesFormik}
                    type={fieldsAddPostTypes.phone}
                    errors={errors}
                    setValuesFormik={setValuesFormik}
                  />
                </div>
                <FieldsAddPost type={fieldsAddPostTypes.messages} />{" "}
              </>
            }
            errors={errors}
            values={values}
            setValues={setValues}
            valuesFormik={valuesFormik}
          />
          <PreviewButtonBlock
            variablesAccessEdit={variablesAccessEdit}
            values={values}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
        </Form>
      )}
    </Formik>
  );
};

Carrier.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  editPostData: PropTypes.shape({
    description: PropTypes.string,
    images: PropTypes.array,
    isAgency: PropTypes.bool,
    title: PropTypes.string,
    seo: PropTypes.string,
    linkYouTube: PropTypes.string,
    isSendToEmail: PropTypes.bool,
    addressMap: PropTypes.object,
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      isViber: PropTypes.bool,
      isTelegram: PropTypes.bool,
      isWhatsapp: PropTypes.bool
    }),
    carrier: PropTypes.shape({
      carrierCountryDepartureId: PropTypes.string,
      carrierCountryArrivalId: PropTypes.string,
      cityDeparture: PropTypes.string,
      cityArrival: PropTypes.string,
      cityAlongRoute: PropTypes.string,
      dateDeparture: PropTypes.string,
      countryAlongRouteIds: PropTypes.array
    })
  }),
  variablesAccessEdit: PropTypes.shape({
    variables: PropTypes.shape({
      code: PropTypes.string,
      postId: PropTypes.string
    }),
    isEdit: PropTypes.bool
  }),
  refetch: PropTypes.func,
  dateDeparture: PropTypes.string
};

Carrier.defaultProps = {
  editPostData: {}
};

export default Carrier;
