import { gql } from "@apollo/client";

const registration = gql`
  mutation registration($input: RegistrationInput!) {
    registration(input: $input) {
      success
      code
    }
  }
`;

export default registration;
