/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { pageSize, postTypes } from "../../../../graphql/enum";
import {
  getArrayIfNotEmpty,
  getOffset,
  isAgencyFilter
} from "../../../../utility";
import PostTable from "../../../layout/PostTable";

export const checkInsQuery = gql`
  query posts($filters: PostFilters, $pagination: PaginationInput) {
    posts {
      total(filters: $filters)
      data(pagination: $pagination, filters: $filters) {
        id
        title
        seo
        type
        city {
          id
          title
        }
        user {
          phone
          name
          id
          email
          isTelegram
          isViber
          isWhatsapp
        }
        rentProperty {
          postRentPropertyCategory {
            title
          }
        }
        expireInPriorityDate
        priority
      }
    }
  }
`;

const RentPropertyTable = ({
  filters,
  setTotalPosts,
  currentPage,
  setCurrentPage
}) => {
  const numberOfRoom = getArrayIfNotEmpty(filters.numberOfRoom);
  const numberOfBathroom = getArrayIfNotEmpty(filters.numberOfBathroom);
  const { loading, data, refetch } = useQuery(checkInsQuery, {
    variables: {
      filters: {
        type: postTypes.rentProperty,
        search: filters.search || null,
        cityIds: filters.cityId ? [filters.cityId] : null,
        postIndexIds: getArrayIfNotEmpty(filters.postIndexs),
        typeRoomsIds: getArrayIfNotEmpty(filters.typeRooms),
        rentalPeriodsIds: getArrayIfNotEmpty(filters.rentalPeriods),
        postRentPropertyCategoryIds: getArrayIfNotEmpty(
          filters.postRentPropertyCategoryIds
        ),
        numberOfRoom: numberOfRoom
          ? numberOfRoom.map((t) => parseInt(t, 10))
          : null,
        numberOfBathroom: numberOfBathroom
          ? numberOfBathroom.map((t) => parseInt(t, 10))
          : null,
        isGarden: filters.garden.length ? true : null,
        isCarParking: filters.carParking.length ? true : null,
        isBicycleSpace: filters.isBicycleSpace.length ? true : null,
        isPropertyForSale: filters.isPropertyForSale.length ? true : null,
        isWifi: filters.isWifi.length ? true : null,
        isUtilitiesIncluded: filters.utilitiesIncluded.length ? true : null,
        isAgency: isAgencyFilter(filters)
      },
      pagination: {
        limit: pageSize,
        offset: getOffset({ currentPage, pageSize })
      }
    }
  });

  setTotalPosts(data?.posts?.total || 0);

  return (
    <PostTable
      pageSize={pageSize}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      posts={data?.posts?.data || []}
      total={data?.posts?.total || 0}
      loading={loading}
      refetch={refetch}
    />
  );
};

RentPropertyTable.propTypes = {
  filters: PropTypes.object,
  setTotalPosts: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func
};
export default RentPropertyTable;
