/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import { t } from "i18next";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { createPostMutationsCodes, postTypes } from "../../../../graphql/enum";
import createPost from "../../../../graphql/mutation/createPost";
import updatePost from "../../../../graphql/mutation/updatePost";
import { addPostUrl } from "../../../../routes";
import {
  clearHTMLTags,
  getCreatePostTypeUser,
  getValueId,
  getValues,
  getValuesBoolean,
  removeSpaces,
  validateEmail,
  validatePhone
} from "../../../../utility";
import Loading from "../../../layout/Loading/Loading";
import ContactInformationBlock from "../CreatePostLayout/ContactInformationBlock";
import ContainerAddPost from "../CreatePostLayout/ContainerAddPost";
import PreviewButtonBlock from "../CreatePostLayout/PreviewButtonBlock";
import { getUrlAfterFirstStateUpdate } from "../CreatePostLayout/utils";
import FirstBlock from "./FirstBlock";
import SecondBlock from "./SecondBlock";

export const allDataQuery = gql`
  query allData {
    cities {
      id
      title
    }
    postIndexs {
      id
      title
    }
    categoryProfession {
      id
      title
      professions {
        id
        title
      }
    }
  }
`;
const LookingJob = ({ editPostData, variablesAccessEdit, refetch, user }) => {
  const history = useHistory();
  const { loading, data } = useQuery(allDataQuery);

  const addressMap = getValues(
    editPostData?.addressMap,
    variablesAccessEdit.isEdit
  );

  const [values, setValues] = useState({
    description: getValues(
      editPostData?.description,
      variablesAccessEdit.isEdit
    ),
    postIndexIds: getValueId(
      editPostData?.postIndexIds,
      variablesAccessEdit.isEdit
    ),
    cityInEngland: getValueId(
      editPostData?.lookingJob?.cityId,
      variablesAccessEdit.isEdit
    ),
    categoryProfession: getValueId(
      editPostData?.lookingJob?.categoryProfessionId,
      variablesAccessEdit.isEdit
    ),
    profession:
      getValueId(
        editPostData?.lookingJob?.professions?.map(({ id }) => id),
        variablesAccessEdit.isEdit
      ) || [],
    imagesIds: variablesAccessEdit.isEdit
      ? editPostData?.images.map(({ id }) => id)
      : [],
    fileId: variablesAccessEdit.isEdit ? editPostData?.resumeFileId : null,
    typeUser: getCreatePostTypeUser(
      editPostData?.isAgency,
      variablesAccessEdit.isEdit
    ),
    address: addressMap
      ? { title: addressMap.title, lat: addressMap.lat, lng: addressMap.lng }
      : null
  });

  const [createPostMutation] = useMutation(createPost);
  const [updatePostMutation] = useMutation(updatePost);

  const validate = (valuesValidate) => {
    const errors = {};

    if (!valuesValidate.title) {
      errors.title = `validationErrors.required`;
    }
    if (valuesValidate.email && !validateEmail(valuesValidate.email)) {
      errors.email = `validationErrors.required`;
    }
    if (removeSpaces(valuesValidate.title).length < 10) {
      errors.title = `validationErrors.min10`;
    }
    if (valuesValidate.email && !validateEmail(valuesValidate.email)) {
      errors.email = `validationErrors.required`;
    }
    if (!validatePhone(valuesValidate.phone)) {
      errors.phone = `validationErrors.required`;
    }
    if (removeSpaces(clearHTMLTags(values?.description)).length < 20) {
      errors.description = `validationErrors.min20`;
    }
    if (clearHTMLTags(values?.description)?.length > 500) {
      errors.description = `validationErrors.max500`;
    }
    if (!values.description) {
      errors.description = `validationErrors.required`;
    }
    if (!values.categoryProfession) {
      errors.categoryProfession = `validationErrors.required`;
    }
    if (!values.profession.length) {
      errors.profession = `validationErrors.required`;
    }

    return errors;
  };
  const onSave = async (valuesFormik, { setErrors }) => {
    const valid = validate(valuesFormik);
    setErrors(valid);

    const titleBlock = {
      title: valuesFormik.title,
      postIndexIds: values.postIndexIds,
      cityId: values.cityInEngland,
      categoryProfessionId: values.categoryProfession,
      professionIds: values.profession,
      isWorkForAMarriedCouple: valuesFormik.workForAMarriedCouple,
      isYouCanWorkOnWeekends: valuesFormik.youCanWorkOnWeekends,
      isAssistantJob: valuesFormik.assistantJob,
      isLookingJobMinors: valuesFormik.lookingJobMinors
    };

    const descriptionBlock = {
      description: values.description,
      linkYouTube: valuesFormik.linkYouTube,
      resumeFileId: values.fileId,
      imagesIds: values.imagesIds,
      isUTR: valuesFormik.isUTR,
      isDriverLicense: valuesFormik.isDriverLicense,
      isNationalInsurance: valuesFormik.isNationalInsurance,
      isTools: valuesFormik.isTools,
      isCar: valuesFormik.isCar
    };

    const contactBlock = {
      name: valuesFormik.name,
      email: valuesFormik.email,
      phone: valuesFormik.phone.toString(),
      isViber: valuesFormik.viber,
      isTelegram: valuesFormik.telegram,
      isWhatsapp: valuesFormik.whatsApp,
      isSendToEmail: valuesFormik.isSendMessageEmail,
      isAgency: values.typeUser === "agency",
      addressMap: values.address
    };
    if (variablesAccessEdit.isEdit) {
      const { data: updatePostData } = await updatePostMutation({
        variables: {
          ...variablesAccessEdit.variables,

          input: {
            type: postTypes.lookingJob,
            ...titleBlock,
            ...descriptionBlock,
            ...contactBlock
          }
        }
      });

      if (
        updatePostData?.updatePost?.code ===
        createPostMutationsCodes.authorBlocked
      ) {
        setErrors({
          phone: `validationErrors.authorBlocked`
        });
        return;
      }

      if (
        updatePostData?.updatePost?.code ===
        createPostMutationsCodes.uniquenessFaild
      ) {
        setErrors({
          description: `validationErrors.uniquenessFaild`
        });
        return;
      }
      const url = getUrlAfterFirstStateUpdate({
        variablesAccessEdit,
        postSeo: editPostData.seo
      });
      await refetch();
      history.push(url);
      return;
    }
    const { data: dataMutation } = await createPostMutation({
      variables: {
        input: {
          type: postTypes.lookingJob,
          ...titleBlock,
          ...descriptionBlock,
          ...contactBlock
        }
      }
    });

    if (
      dataMutation?.createPost?.code === createPostMutationsCodes.authorBlocked
    ) {
      setErrors({
        phone: `validationErrors.authorBlocked`
      });
      return;
    }

    if (
      dataMutation?.createPost?.code ===
      createPostMutationsCodes.uniquenessFaild
    ) {
      setErrors({
        description: `validationErrors.uniquenessFaild`
      });
      return;
    }
    const url = queryString.stringifyUrl({
      url: addPostUrl,
      query: {
        code: dataMutation.createPost.accessCode
      }
    });
    history.push(url);
  };
  if (loading) return <Loading />;
  if (!data) return null;

  return (
    <Formik
      initialValues={{
        title: getValues(editPostData?.title, variablesAccessEdit.isEdit),
        linkYouTube: getValues(
          editPostData?.linkYouTube,
          variablesAccessEdit.isEdit
        ),
        name:
          getValues(editPostData?.user?.name, variablesAccessEdit.isEdit) ||
          (!variablesAccessEdit.isEdit ? user?.name : ""),
        email:
          getValues(editPostData?.user?.email, variablesAccessEdit.isEdit) ||
          (!variablesAccessEdit.isEdit ? user?.email : ""),
        phone:
          getValues(editPostData?.user?.phone, variablesAccessEdit.isEdit) ||
          (!variablesAccessEdit.isEdit ? user?.phone : ""),
        viber: getValuesBoolean(
          editPostData?.user?.isViber,
          variablesAccessEdit.isEdit
        ),
        telegram: getValuesBoolean(
          editPostData?.user?.isTelegram,
          variablesAccessEdit.isEdit
        ),
        whatsApp: getValuesBoolean(
          editPostData?.user?.isWhatsapp,
          variablesAccessEdit.isEdit
        ),
        workForAMarriedCouple: getValuesBoolean(
          editPostData?.lookingJob?.isWorkForAMarriedCouple,
          variablesAccessEdit.isEdit
        ),
        lookingJobMinors: getValuesBoolean(
          editPostData?.lookingJob?.isLookingJobMinors,
          variablesAccessEdit.isEdit
        ),
        assistantJob: getValuesBoolean(
          editPostData?.lookingJob?.isAssistantJob,
          variablesAccessEdit.isEdit
        ),
        youCanWorkOnWeekends: getValuesBoolean(
          editPostData?.lookingJob?.isYouCanWorkOnWeekends,
          variablesAccessEdit.isEdit
        ),
        isNationalInsurance: getValuesBoolean(
          editPostData?.lookingJob?.isNationalInsurance,
          variablesAccessEdit.isEdit
        ),
        isUTR: getValuesBoolean(
          editPostData?.lookingJob?.isUTR,
          variablesAccessEdit.isEdit
        ),
        isDriverLicense: getValuesBoolean(
          editPostData?.lookingJob?.isDriverLicense,
          variablesAccessEdit.isEdit
        ),
        isTools: getValuesBoolean(
          editPostData?.lookingJob?.isTools,
          variablesAccessEdit.isEdit
        ),
        isCar: getValuesBoolean(
          editPostData?.lookingJob?.isCar,
          variablesAccessEdit.isEdit
        ),
        isSendMessageEmail: getValuesBoolean(
          editPostData?.isSendToEmail,
          variablesAccessEdit.isEdit
        )
      }}
      onSubmit={onSave}
      validate={validate}
      validateOnChange
    >
      {({
        errors,
        validateForm,
        values: valuesFormik,
        submitForm,
        isSubmitting,
        setValues: setValuesFormik
      }) => (
        <Form>
          <ContainerAddPost>
            <FirstBlock
              {...{
                data,
                errors,
                values,
                setValues,
                validateForm,
                valuesFormik,
                setValuesFormik
              }}
            />
          </ContainerAddPost>
          <ContainerAddPost
            title={t("createPost.optionalFields")}
            description={t("createPost.optionalFieldsDescription")}
          >
            <SecondBlock
              {...{
                values,
                setValues,
                errors,
                validateForm,
                data,
                images: editPostData?.images
              }}
            />
          </ContainerAddPost>
          <ContactInformationBlock
            errors={errors}
            values={values}
            setValues={setValues}
            valuesFormik={valuesFormik}
          />
          <PreviewButtonBlock
            variablesAccessEdit={variablesAccessEdit}
            values={values}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

LookingJob.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  editPostData: PropTypes.shape({
    description: PropTypes.string,
    images: PropTypes.array,
    resumeFileId: PropTypes.string,
    isAgency: PropTypes.bool,
    title: PropTypes.string,
    linkYouTube: PropTypes.string,
    seo: PropTypes.string,
    addressMap: PropTypes.object,
    isSendToEmail: PropTypes.bool,
    postIndexIds: PropTypes.array,
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      isViber: PropTypes.bool,
      isTelegram: PropTypes.bool,
      isWhatsapp: PropTypes.bool
    }),
    lookingJob: PropTypes.shape({
      cityId: PropTypes.string,
      cityInEngland: PropTypes.string,
      categoryProfessionId: PropTypes.string,
      professions: PropTypes.array,
      isWorkForAMarriedCouple: PropTypes.bool,
      isYouCanWorkOnWeekends: PropTypes.bool,
      isLookingJobMinors: PropTypes.bool,
      isAssistantJob: PropTypes.bool,
      isUTR: PropTypes.bool,
      isDriverLicense: PropTypes.bool,
      isNationalInsurance: PropTypes.bool,
      isTools: PropTypes.bool,
      isCar: PropTypes.bool
    })
  }),
  variablesAccessEdit: PropTypes.shape({
    variables: PropTypes.shape({
      code: PropTypes.string,
      postId: PropTypes.string
    }),
    isEdit: PropTypes.bool
  }),
  refetch: PropTypes.func
};

LookingJob.defaultProps = {
  editPostData: {}
};
export default LookingJob;
