import { gql } from "@apollo/client";

const deleteAuthorPosts = gql`
  mutation deleteAuthorPosts($phone: String!) {
    deleteAuthorPosts(phone: $phone) {
      success
      code
    }
  }
`;

export default deleteAuthorPosts;
