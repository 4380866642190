export const mutationCodes = {
  success: "SUCCESS",
  unauthorized: "UNAUTHORIZED",
  accessDenied: "ACCESS_DENIED",
  authorBlocked: "AUTHOR_BLOCKED"
};
export const test = {
  success: "SUCCESS",
  unauthorized: "UNAUTHORIZED",
  accessDenied: "ACCESS_DENIED"
};

export const postTypes = {
  announcements: "ANNOUNCEMENT",
  offerVacancy: "OFFER_VACANCY",
  lookingJob: "LOOKING_JOB",
  rentProperty: "RENT_PROPERTY",
  carrier: "CARRIER"
};

export const registrationCode = {
  success: "SUCCESS",
  userExists: "USER_EXISTS"
};

export const createPostMutationsCodes = {
  success: "SUCCESS",
  unauthorized: "UNAUTHORIZED",
  authorBlocked: "UNIQUENESS_FAILD",
  accessDenied: "ACCESS_DENIED",
  uniquenessFaild: "UNIQUENESS_FAILD"
};

export const userRoles = {
  admin: "ADMIN",
  user: "USER"
};

export const typeField = {
  city: "city",
  typeRooms: "typeRooms",
  postIndex: "postIndex",
  postIndexs: "postIndexs",
  rentalPeriod: "rentalPeriod",
  price: "price",
  numberOfRooms: "numberOfRooms",
  numberOfBathroom: "numberOfBathroom",
  carParking: "carParking",
  garden: "garden",
  utilitiesIncluded: "utilitiesIncluded",
  categoryProfession: "categoryProfession",
  professions: "professions",
  workForAMarriedCouple: "workForAMarriedCouple",
  youCanWorkOnWeekends: "youCanWorkOnWeekends",
  contractIn: "contractIn",
  wifi: "wifi",
  isWorkForAMarriedCouple: "isWorkForAMarriedCouple",
  isYouCanWorkOnWeekends: "isYouCanWorkOnWeekends",
  departure: "departure",
  arrival: "arrival",
  cityAlongRoute: "cityAlongRoute",
  countryAlongRoute: "countryAlongRoute",
  dateDeparture: "dateDeparture",
  category: "category",
  propertyForSale: "propertyForSale",
  bicycleSpace: "bicycleSpace",
  announcementCategory: "announcementCategory",
  postRentPropertyCategory: "postRentPropertyCategory",
  paymentPeriod: "paymentPeriod",
  paymentPeriodRent: "paymentPeriodRent",
  isWorkForMinors: "isWorkForMinors",
  isAssistantJob: "isAssistantJob",
  isLookingJobMinors: "lookingJobMinors",
  workSchedule: "workSchedule",
  ilookingForJob: "ilookingForJob",
  forWorkThereAre: "forWorkThereAre",
  alarm: "alarm",
  announcementRubric: "announcementRubric"
};

export const numberOfRooms = [
  { id: 1, title: "1" },
  { id: 2, title: "2" },
  { id: 3, title: "3" },
  { id: 4, title: "4" },
  { id: 5, title: "5" },
  { id: 6, title: "6" },
  { id: 7, title: "7" },
  { id: 8, title: "8" },
  { id: 9, title: "9" }
];
export const numberOfBathrooms = [
  { id: "1", title: "1" },
  { id: "2", title: "2" },
  { id: "3", title: "3" },
  { id: "4", title: "4" }
];

export const postRentPropertyCategorys = {
  rentalOfProperty: "rentalOfProperty",
  realEstateExchange: "realEstateExchange"
  // sellingRealEstate: "sellingRealEstate",
  // iWillBuyRealEstate: "iWillBuyRealEstate"
};
export const pageSize = 100;
