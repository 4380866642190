/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { registrationCode } from "../../graphql/enum";
import registration from "../../graphql/mutation/registration";
import { homeUrl, loginUrl, registrationUrl } from "../../routes";
import { handlePhobePress, validateEmail, validatePhone } from "../../utility";
import { UserContext } from "../../utility/context/User";
import Input from "../layout/Input/Input";
import LinkView from "../layout/Link";
import Page from "../layout/Page";
import SuccessSendRegistarionEmail from "./SuccessSendRegistarionEmail";

const Registration = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [sendToEmailRequest, setSendToEmailRequest] = useState();

  const breadcrumbs = [
    { id: "home", title: t("home"), link: homeUrl },
    {
      id: "registration",
      title: t("registration.registration"),
      link: registrationUrl
    }
  ];
  const [registrationMutation] = useMutation(registration);

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = `validationErrors.required`;
    }
    if (values.name.length < 3) {
      errors.name = `validationErrors.required`;
    }
    if (!values.email) {
      errors.email = `validationErrors.required`;
    }
    if (!validateEmail(values.email)) {
      errors.email = `validationErrors.required`;
    }
    if (!validatePhone(values.phone)) {
      errors.phone = `validationErrors.required`;
    }

    if (!values.password) {
      errors.password = `validationErrors.required`;
    }
    if (values.password.length < 7) {
      errors.password = `validationErrors.required`;
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = `validationErrors.required`;
    }
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = `validationErrors.required`;
    }
    return errors;
  };

  const onSave = async (formikValues, { setErrors }) => {
    const { data } = await registrationMutation({
      variables: {
        input: {
          name: formikValues.name,
          email: formikValues.email,
          password: formikValues.password,
          phone: formikValues.phone,
          lang: user.lang
        }
      }
    });
    if (data?.registration.success) {
      setSendToEmailRequest(formikValues.email);
    }
    if (data?.registration.code === registrationCode.userExists) {
      setErrors({
        email: `validationErrors.required`,
        phone: `validationErrors.required`
      });
    }
    return null;
  };

  return (
    <Page
      pageName={t("registration.registration")}
      isClassNamePageSingle={false}
      breadcrumbs={breadcrumbs}
    >
      {sendToEmailRequest ? (
        <SuccessSendRegistarionEmail email={sendToEmailRequest} />
      ) : (
        <div className="account-form">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-md-6 col-xl-4">
              <h1 className="account-form-heading">
                {t("registration.registration")}
              </h1>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  password: "",
                  confirmPassword: ""
                }}
                onSubmit={onSave}
                validate={validate}
              >
                {({
                  errors,
                  values: valuesFormik,
                  setValues: setValuesFormik
                }) => (
                  <Form>
                    <div className="mb-3">
                      <Input
                        type="text"
                        label={t("registration.name")}
                        id="name"
                        name="name"
                        maxLength="80"
                        required="1"
                        error={errors?.name}
                      />
                    </div>
                    <div className="mb-3">
                      <Input
                        type="text"
                        label={t("registration.email")}
                        id="email"
                        name="email"
                        required="1"
                        maxLength="50"
                        error={errors?.email}
                      />
                    </div>
                    <div className="mb-3">
                      <Input
                        type="text"
                        label={t("registration.phone")}
                        id="phone"
                        placeholder="+"
                        name="phone"
                        maxLength="13"
                        onKeyUp={handlePhobePress({
                          valuesFormik,
                          setValuesFormik
                        })}
                        required="1"
                        error={errors?.phone}
                      />
                    </div>
                    <div className="mb-3">
                      <Input
                        type="password"
                        label={t("registration.password")}
                        id="password"
                        name="password"
                        required="1"
                        error={errors?.password}
                      />
                    </div>
                    <div className="mb-3">
                      <Input
                        type="password"
                        label={t("registration.confirmPassword")}
                        id="confirmPassword"
                        name="confirmPassword"
                        required="1"
                        error={errors?.confirmPassword}
                      />
                    </div>
                    <div className="mb-3">
                      <p className="account-form-registration-policy">
                        {t("registration.description")}
                      </p>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 mb-3 mb-lg-0 order-lg-2">
                        <button name="" className="btn btn-primary w-100">
                          {t("registration.continue")}
                        </button>
                      </div>
                      <div className="col-lg-6 order-lg-1 text-center text-lg-start">
                        <LinkView
                          className="account-form-registration-otherLink"
                          to={loginUrl}
                        >
                          {t("registration.thereIsAlreadyAnAccount")}
                        </LinkView>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

Registration.propTypes = {
  filter: PropTypes.object,
  valuesFormik: PropTypes.object,
  setValuesFormik: PropTypes.func
};

export default Registration;
