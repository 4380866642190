/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable consistent-return */
/* eslint-disable max-statements */
/* eslint-disable react/prop-types */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import queryString from "query-string";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";

import AutorizeUser from "../../components/layout/AutorizeUser";
import Input from "../../components/layout/Input/Input";
import LinkView from "../../components/layout/Link";
import {
  addPostUrl,
  advertisementUrl,
  carrierUrl,
  companyUrl,
  homeUrl,
  iOfferAVacancyUrl,
  lookingForAJobUrl,
  myOfficeUrl,
  privateAnnouncementUrl,
  questionnairesListUrl,
  registrationUrl,
  rentUrl,
  siteArticlesUrl,
  surveyUrl
} from "../../routes";
import { classNames } from "../../utility";
import { getNavigationMenu } from "../../utility/config";
import LanguageSelect from "./LanguageSelect";
import MenuHeader from "./Menus/Menu";
import CompaniesAndServices from "./Modals/CompaniesAndServices";
import NoLangGB from "./Modals/NoLangGB";
import NoLangRO from "./Modals/NoLangRO";
import NoLangUA from "./Modals/NoLangUA";

const postsQuery = gql`
  query posts {
    posts {
      total
    }
  }
`;
const Header = ({ user, pathname, logout }) => {
  const location = useLocation();
  const navigationMenu = getNavigationMenu(user, pathname);
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = useQuery(postsQuery, {
    variables: {}
  });
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const [isShowMobileMenuAccount, setIsShowMobileMenuAccount] = useState(false);
  const [isShowMobileMenuTypePosts, setIsShowMobileMenuTypePosts] =
    useState(false);
  const [isShowCompaniesAndServices, setIsShowCompaniesAndServices] =
    useState(false);
  const [isShowMobileLanguage, setIsShowMobileLanguage] = useState(false);
  const [isShowNoLangUA, setIsShowNoLangUA] = useState(false);
  const [isShowNoLangGB, setIsShowNoLangGB] = useState(false);
  const [isShowNoLangRO, setIsShowNoLangRO] = useState(false);
  const curentLocation = `${location.pathname + location.search}`;
  const parameters = new URLSearchParams(window.location.search);

  const onSave = (value) => {
    const filerPage = [
      carrierUrl,
      privateAnnouncementUrl,
      rentUrl,
      lookingForAJobUrl,
      iOfferAVacancyUrl
    ];
    const isFilterPage = filerPage.find((x) => curentLocation.search(x) !== -1);
    const d = isFilterPage ? curentLocation : homeUrl;

    const url = queryString.stringifyUrl({
      url: d,
      query: value
    });
    history.push(url);
    history.go(0);
  };

  return (
    <>
      <header className="header">
        <div className="container" key={new Date().getSeconds()}>
          <div className="header-inner">
            <div className="header-logo">
              <Link to={homeUrl}>
                <img src="/assets/img/logo.svg" alt="" />
              </Link>
            </div>
            <MenuHeader
              setIsShowCompaniesAndServices={setIsShowCompaniesAndServices}
              navigation={navigationMenu}
            />
            <div className="header-btn d-none d-lg-flex">
              <Link to={addPostUrl} className="btn btn-primary">
                {t("header.addPost")}
              </Link>
            </div>
            <div className="header-account d-none">
              <AutorizeUser
                notAutorizeComponent={
                  <LinkView to={registrationUrl}></LinkView>
                }
                autorizeComponent={
                  <LinkView to={myOfficeUrl}>
                    <i></i>
                  </LinkView>
                }
              />
            </div>
            <LanguageSelect
              setIsShowNoLangGB={setIsShowNoLangGB}
              setIsShowNoLangRO={setIsShowNoLangRO}
              setIsShowNoLangUA={setIsShowNoLangUA}
              classname="d-lg-none"
              isShow={isShowMobileLanguage}
              setIsShow={setIsShowMobileLanguage}
            />
            <div className="header-toggle d-lg-none">
              <button
                id="menu-toggled"
                onClick={() => setIsShowMobileMenu(true)}
              ></button>
            </div>
          </div>
        </div>
      </header>
      <div className="header-bottom">
        <div className="container">
          <div className="header-inner">
            <div className="header-search">
              <Formik
                initialValues={{
                  search: parameters.getAll("search")
                }}
                onSubmit={onSave}
              >
                <Form className="search-form">
                  <Input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder={t("header.findAdsFrom", {
                      count: data?.posts?.total || 0
                    })}
                    errorclassname={{ notError: "d-none" }}
                  />
                  <button type="submit"></button>
                </Form>
              </Formik>
            </div>
            <AutorizeUser
              notAutorizeComponent={
                <div className="header-account d-none d-lg-block">
                  {/* <LinkView to={registrationUrl}> */}
                  <LinkView to={registrationUrl}>
                    {t("header.registrationLogin")}
                  </LinkView>
                </div>
              }
              autorizeComponent={
                <div
                  className={classNames(
                    "header-account d-none d-lg-block",
                    isShowMobileMenuAccount ? "active" : ""
                  )}
                >
                  <LinkView
                    to={myOfficeUrl}
                    onClick={() =>
                      setIsShowMobileMenuAccount(!isShowMobileMenuAccount)
                    }
                    className="header-user-is-logged"
                  >
                    <i></i> {t("header.personalArea")}
                  </LinkView>
                  {/*
                  Казав забрати для десктопа
                  <ul className="account-menu">
                    <li>
                      <Link to={myOfficeUrl}>{t("office.yourAds")}</Link>
                    </li>
                    <li>
                      <Link to={advertisementUrl}>
                        {t("office.yourAdvertisement")}
                      </Link>
                    </li>
                    <li>
                      <Link to={surveyUrl}>{t("office.survey")}</Link>
                    </li>
                    <li>
                      <Link to={companyUrl}>{t("office.company")}</Link>
                    </li>
                    <li>
                      <Link to={`${myOfficeUrl}?page=settings`}>
                        {t("office.settings")}
                      </Link>
                    </li>
                  </ul> */}
                </div>
              }
            />
            <div className="header-btn d-lg-none">
              <Link to={addPostUrl} className="btn btn-primary">
                {t("header.addPost")}
              </Link>
            </div>
            <LanguageSelect
              setIsShowNoLangGB={setIsShowNoLangGB}
              setIsShowNoLangRO={setIsShowNoLangRO}
              setIsShowNoLangUA={setIsShowNoLangUA}
              classname="d-none d-lg-block"
              isShow={isShowMobileLanguage}
              setIsShow={setIsShowMobileLanguage}
            />
          </div>
        </div>
      </div>
      {/* mobile menu */}
      <div className={`modal-mobile ${isShowMobileMenu ? "show" : ""}`}>
        <div className="modal-mobile-bg"></div>
        <div className="modal-mobile-wrap">
          <button
            className="modal-mobile-close"
            onClick={() => setIsShowMobileMenu(false)}
          ></button>
          <ul className="main-menu">
            <li className="is-active">
              <Link to={homeUrl}>
                <img src="/assets/img/menu-icon-home.svg" alt="" />
                {t("header.home")}
              </Link>
            </li>
            <li>
              <Link to={questionnairesListUrl}>
                <img src="/assets/img/menu-icon-checklist.svg" alt="" />
                {t("header.questionnairesOfWorkersUrl")}
              </Link>
            </li>
            <li>
              {/* <Link to={catalogOfCompaniesUrl}> */}
              <Link
                to="#"
                onClick={() => {
                  setIsShowCompaniesAndServices(true);
                  setIsShowMobileMenu(false);
                }}
              >
                <img src="/assets/img/menu-icon-file.svg" alt="" />
                {t("header.catalogOfCompaniesUrl")}
              </Link>
            </li>
            <li>
              <Link to={siteArticlesUrl}>
                <img src="/assets/img/menu-icon-brochure.svg" alt="" />
                {t("header.siteArticles")}
              </Link>
            </li>
            <li
              className={classNames(
                "main-menu-parent",
                isShowMobileMenuTypePosts ? "active" : ""
              )}
            >
              <Link
                to="/"
                onClick={() =>
                  setIsShowMobileMenuTypePosts(!isShowMobileMenuTypePosts)
                }
              >
                <img src="/assets/img/menu-icon-user.svg" alt="" />
                {t("header.posts")}
              </Link>
              <span className="main-menu-arrow"></span>
              <ul className="sub-menu">
                <li>
                  <Link to={homeUrl}>{t("header.allPosts")}</Link>
                </li>
                <li>
                  <Link to={iOfferAVacancyUrl}>
                    {t("createPost.iOfferAVacancy")}
                  </Link>
                </li>
                <li>
                  <Link to={lookingForAJobUrl}>
                    {t("createPost.lookingForAJob")}
                  </Link>
                </li>
                <li>
                  <Link to={privateAnnouncementUrl}>
                    {t("createPost.announcement")}
                  </Link>
                </li>
                <li>
                  <Link to={carrierUrl}>{t("createPost.carriers")}</Link>
                </li>
                <li>
                  <Link to={rentUrl}>{t("createPost.rentalHousing")}</Link>
                </li>
              </ul>
            </li>
            <AutorizeUser
              autorizeComponent={
                <>
                  <li
                    className={classNames(
                      "main-menu-parent",
                      isShowMobileMenuAccount ? "active" : ""
                    )}
                  >
                    <Link
                      to="/"
                      onClick={() =>
                        setIsShowMobileMenuAccount(!isShowMobileMenuAccount)
                      }
                    >
                      <img src="/assets/img/menu-icon-user.svg" alt="" />
                      {t("header.personalArea")}
                    </Link>
                    <span className="main-menu-arrow"></span>
                    <ul className="sub-menu">
                      <li>
                        <Link to={myOfficeUrl}>{t("office.yourAds")}</Link>
                      </li>
                      <li>
                        <Link to={advertisementUrl}>
                          {t("office.yourAdvertisement")}
                        </Link>
                      </li>
                      <li>
                        <Link to={surveyUrl}>{t("office.survey")}</Link>
                      </li>
                      <li>
                        <Link to={companyUrl}>{t("office.company")}</Link>
                      </li>
                      <li>
                        <Link to={`${myOfficeUrl}?page=settings`}>
                          {t("office.settings")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/" onClick={() => logout()}>
                      <img src="/assets/img/menu-icon-logout.svg" alt="" />
                      {t("footer.getOutOfAccount")}
                    </Link>
                  </li>
                </>
              }
            />
          </ul>
        </div>
      </div>
      <CompaniesAndServices
        isShow={isShowCompaniesAndServices}
        onClose={() => setIsShowCompaniesAndServices(false)}
      />
      <NoLangUA
        isShow={isShowNoLangUA}
        onClose={() => setIsShowNoLangUA(false)}
      />
      <NoLangGB
        isShow={isShowNoLangGB}
        onClose={() => setIsShowNoLangGB(false)}
      />
      <NoLangRO
        isShow={isShowNoLangRO}
        onClose={() => setIsShowNoLangRO(false)}
      />
    </>
  );
};

export default Header;
