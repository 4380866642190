/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { pageSize } from "../../graphql/enum";
import {
  carrierUrl,
  homeUrl,
  iOfferAVacancyUrl,
  lookingForAJobUrl,
  privateAnnouncementUrl,
  rentUrl
} from "../../routes";
import { getOffset } from "../../utility";
import Link from "../layout/Link";
import Page from "../layout/Page";
import PostTable from "../layout/PostTable";
import Sidebar from "./SideBar";

export const checkInsQuery = gql`
  query posts($filters: PostFilters, $pagination: PaginationInput) {
    posts {
      total(filters: $filters)
      data(pagination: $pagination, filters: $filters) {
        id
        title
        seo
        type
        city {
          id
          title
        }
        expireInPriorityDate
        rentProperty {
          postRentPropertyCategory {
            title
          }
        }
        user {
          phone
          name
          id
          email
          isTelegram
          isViber
          isWhatsapp
          ip
        }
        priority
      }
    }
  }
`;
const Home = () => {
  const { t } = useTranslation();
  const parameters = new URLSearchParams(window.location.search);

  const page = parameters.getAll("currentPage")[0];
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);

  const search = parameters.getAll("search")[0];

  const { loading, data, refetch } = useQuery(checkInsQuery, {
    variables: {
      filters: {
        search: search || null
      },
      pagination: {
        limit: pageSize,
        offset: getOffset({ currentPage, pageSize })
      }
    }
  });

  if (loading && !data) return null;

  return (
    <Page
      sidebar={{
        classNameMobileTextButton: "no-icon",
        mobileTextButton: t("advertisement"),
        render: () => <Sidebar />
      }}
      beforeSideBarInContent={
        <ul className="type-menu type-menu-horizontal">
          <li>
            <Link to={homeUrl} className="btn btn-outline-primary active w-100">
              {t("filsersPost.allPosts")}
            </Link>
          </li>{" "}
          <li>
            <Link
              to={iOfferAVacancyUrl}
              className={"btn btn-outline-primary w-100"}
            >
              {t("filsersPost.iOfferAVacancy")}
            </Link>
          </li>
          <li>
            <Link
              to={lookingForAJobUrl}
              className={"btn btn-outline-primary w-100"}
            >
              {t("filsersPost.lookingForAJob")}
            </Link>
          </li>
          <li>
            <Link
              to={privateAnnouncementUrl}
              className="btn btn-outline-primary w-100"
            >
              {t("filsersPost.announcements")}
            </Link>
          </li>
          <li>
            <Link to={carrierUrl} className="btn btn-outline-primary w-100">
              {t("filsersPost.carriers")}
            </Link>
          </li>
          <li>
            <Link to={rentUrl} className="btn btn-outline-primary w-100">
              {t("filsersPost.rentalHousing")}
            </Link>
          </li>
        </ul>
      }
    >
      <PostTable
        pageSize={pageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        posts={data?.posts?.data || []}
        total={data?.posts?.total || 0}
        loading={loading}
        refetch={refetch}
      />
    </Page>
  );
};

Home.propTypes = {
  filter: PropTypes.object
};
export default Home;
